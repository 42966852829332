<template>
    <div class="right-side">
        <h2> {{$t('navigation.tender')}} </h2>
        <form class="seach__area " @submit.prevent="searchHandler">
            <div class="search__block-one">
                <input type="text" class="form-control" v-model="filter.name" :placeholder="$t('contracts.keywords')">
                <button type="submit" class="btn "> {{$t('search.search_btn')}} </button>
            </div>
            <div class="search__block-two">
                <div>
                    <a class="search-hide" @click="advancedSearch = !advancedSearch"> {{ $t('search.advanced_search') }} &nbsp; 
                        <img class="arrowdown" :class="{active: advancedSearch}" src="@/assets/img/icons/arrowDown.svg" alt="">
                    </a>
                </div>
            </div>
            <div class="search__block-hide" v-if="advancedSearch">
                <div class="form-row">
                    <div class="col-12 col-lg-8">
                        <input type="text" class="form-control" v-model="filter.exceptions" :placeholder="$t('search.exceptword')">
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="input-group">
                        <input type="text" class="form-control" v-model="filter.priceFrom" :placeholder="$t('search.pricefrom')">
                        <input type="text" class="form-control" v-model="filter.priceTo" :placeholder="$t('search.priceto')">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12">
                        <input type="text" class="form-control " v-model="filter.customer" :placeholder="$t('search.customer')">
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12">
                        <input type="text" class="form-control " v-model="filter.organizer" :placeholder="$t('search.organizer')">
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12 col-lg-8">
                        <v-select 
                            class="style-chooser"
                            v-if="tradeMethod"
                            :label="getFieldName('name')"
                            multiple
                            v-model="filter.tradeMethodId"
                            :reduce="prefix => prefix.id"
                            :placeholder="$t('search.method')"
                            :options="tradeMethod">
                        </v-select> 
                    </div>
                    <div class="col-12 col-lg-4">
                        <v-select 
                            class="style-chooser"
                            v-if="tenderStatus"
                            :label="getFieldName('name')"
                            multiple
                            v-model="filter.tenderStatusId"
                            :reduce="prefix => prefix.id"
                            :placeholder="$t('search.status')"
                            :options="tenderStatus">
                        </v-select> 
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12 col-lg-8 block__flex block__flex1">
                        <v-select 
                            class="style-chooser"
                            v-if="purchaseAreas"
                            label="name"
                            multiple
                            v-model="filter.systemId"
                            :reduce="prefix => prefix.id"
                            :placeholder="$t('search.system')"
                            :options="purchaseAreas">
                        </v-select> 
                    </div>
                    <div class="col-12 col-lg-4">
                        
                        <shops-tree @setCato="setCato" :cato="filter.cato" />

                    </div>
                </div>
                
                <div class="search__block-two">
                    <div>
                        <input id="is_ooi" type="checkbox" v-model="filter.isOOI">
                        <label for="is_ooi" style="font-size: 16px;"> {{$t('search.is_ooi')}} </label>
                    </div>
                    <div>
                        <button type="reset" @click="resetHandler" class="btn btn__reset"> {{$t('search.reset')}} </button>
                        <button type="submit" class="btn btn__search"> {{ $t('search.search_btn') }} </button>
                    </div>
                </div>
            </div>
        </form>
        <div class="table-area">
            <div class="paginator-area">
                <div class="paginator">
                    <paginate
                        v-model="currentPage"
                        :page-count="pageCount"
                        :click-handler="changePage"
                        :prev-text="'&laquo;'"
                        :next-text="'&raquo;'"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                        :page-link-class="'page-link'"
                        :prev-class="'page-item'"
                        :prev-link-class="'page-link'"
                        :next-class="'page-item'"
                        :next-link-class="'page-link'"
                        >
                    </paginate>
                </div>
                <div class="sort-area">
                    <p> {{$t('sort.sort_by')}} </p>
                    <div class="sort-area__select">
                        <v-select 
                            @input="sortHandler"
                            class="style-chooser"
                            v-if="options"
                            label="title"
                            v-model="selected"
                            :reduce="prefix => prefix.sortBy"
                            :placeholder="$t('mainPage.choose')"
                            :options="options"
                        >
                            <template slot="option" slot-scope="option">
                                {{ option.title }}
                                <span class="ml-3" :class="option.icon"></span>
                            </template>
                        </v-select>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger py-5 mb-5" role="alert" v-if="!isAuth && currentPage >= 3">
                <p class="text-center">{{$t("message.isNotAuth")}}</p>
            </div>
            <div v-else>
                <beat-loader 
                    v-if="loading" 
                    class="loader-center" 
                    :color="'#553DB4'" 
                    :loading="loading" 
                    :size="20" 
                    :sizeUnit="'px'"
                /> 
                <shops-table 
                    v-else 
                    :items="TENDERS"
                    @getPlans="getPlans"
                />
                <div v-if="TENDERS == 0" class="text-center not-found">
                    {{ $t("message.nothing_found") }}
                    <button type="reset" @click="resetHandler" class="btn btn__reset"> {{$t('search.reset')}} </button>
                </div>
            </div>
            <div class="paginator-area">
                <div class="paginator">
                    <paginate
                    v-model="currentPage"
                        :page-count="pageCount"
                        :click-handler="changePage"
                        :prev-text="'&laquo;'"
                        :next-text="'&raquo;'"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                        :page-link-class="'page-link'"
                        :prev-class="'page-item'"
                        :prev-link-class="'page-link'"
                        :next-class="'page-item'"
                        :next-link-class="'page-link'"
                        >
                    </paginate>
                </div>
                <div class="quantity__text">
                    <p>{{$t('plan.row_cnt')}}: {{TENDER_LENGTH | sum}}</p>
                </div>
            </div>
        </div>

        <modal-box boxName="tender-info" ref="modalTender" :tender="TenderInfo"></modal-box>
    </div>
</template>

<script>

import shopsTable from '@/components/tenderPage/shopsTable'
// import shopsTree from '@/components/tenderPage/shopsTree'
import shopsTree from '@/components/page/shopsTree'
import ModalBox from '@/components/ui/TenderModalBox'
import {mapGetters} from 'vuex'
import { mixin as clickaway } from "vue-clickaway"
import translateMix from '@/mixins/translate'



    export default {
        name: 'tender',
        mixins: [
            clickaway, 
            translateMix
        ],
        components: {
            shopsTable, shopsTree,
            ModalBox
        },
        props: {
            keywords: String
        },
        data: () => ({
            advancedSearch: false,
            TenderInfo: null,
            selected: null,
            loading: true,
            totalElem: 0,
            size: 15,
            currentPage: 1,
            allTru: null,
            filter: {
                name: null,
                exceptions: null,
                priceFrom: null,
                priceTo: null,
                tradeMethodId: null,
                tenderStatusId: null,
                systemId: null,
                cato: null,
                customer: null,
                organizer: null,
                isOOI: null

            },
            dSystemId: null,
            purchaseAreas: null,
            tradeMethod: null,
            month: null,
            tenderStatus: []

        }),
        mounted() {
            this.onShow();
        },
        methods: {
            onShow() {
                if (this.$route.query.keywords) {
                    this.filter.name = this.$route.query.keywords;
                }

                this.getPlans();
                this.getPurchaseArea()
                this.getTradeMothod()
                this.getStatus()
                this.getCities()
                this.$store.commit('RESET_CLICKED_CITY')

                if (this.$route.params.id) {
                    this.showTender(this.$route.params.id);
                }
            },
            showTender(id) {
                let params = {
                    entity: "Tender",
                    uuid: id
                };

                this.$store.dispatch('CLICKED_LIST', params)
                    .then(res => {
                        this.TenderInfo = res.data
                        this.$refs.modalTender.show();
                    })
                    .catch(() => {
                        this.TenderInfo = null
                        this.$toast.error(this.$t("message.nothing_found"));
                    })
                    ;
            },
            setCato(data) {
                this.filter.cato = data
            },
            getCities() {
                let params = {
                    entity: 'DictionaryItem',
                    filter: {
                        'dictionaryId.code': "cato", 
                        'parentId%isNull': 0
                    },
                    page: 0,
                    length: 1000,
                }
                this.$store.dispatch('GET_CITIES', params)
            },
            getStatus() {
                let params = {
                    entity: 'Taxonomy',
                    filter: {tableField: "tender_status"},
                    page: 0,
                    length: 500,
                }
                this.$store.dispatch('GET_STATUS', params)
                    .then( res => {
                        this.tenderStatus = res.content
                    })
            },
            getMonth() {
                let params = {
                    entity: 'DictionaryItem',
                    filter: {'dictionaryId.code': "Months"},
                    page: 0,
                    length: 500,
                }
                this.$store.dispatch('GET_MONTH', params)
                    .then( res => {
                        this.month = res.content
                    })
            },
            getTradeMothod() {
                let params = {
                    entity: 'MethodTrade',
                    filter: {},
                    page: 0,
                    length: 100,
                }
                this.$store.dispatch('GET_METHOD_TRADE', params)
                    .then( res => {
                        this.tradeMethod = res.content
                    })
            },
            getPurchaseArea() {
                let params = {
                    entity: 'DictionaryItem',
                    filter: {'dictionaryId.code': "System"},
                    page: 0,
                    length: 50,
                }
                this.$store.dispatch('GET_PURCHASE_AREA', params)
                    .then( res => {
                        this.purchaseAreas = res.content
                    })
            },
            searchHandler(){
                this.getPlans()
            },
            clicktruHandler(code) {
                this.filter.ensTru = code
                this.allTru = null
            },
            ensTru(){
                let params = {
                    entity: 'Enstru15',
                    filter: {code: this.filter.ensTru},
                    page: 0,
                    length: 50,
                }

                this.$store.dispatch('GET_TRU', params)
                    .then( res => {
                        this.allTru = res.data.content
                    })
            },
            resetHandler() {
                for (let val in this.filter) {
                    this.filter[val] = null
                }
                this.allTru = null
                this.currentPage = 1
                this.$store.commit('RESET_CLICKED_CITY')
                this.getPlans()
            },
            sortHandler(){
                this.currentPage = 1
                this.getPlans()
            },
            changePage(currentPage) {
                this.currentPage = currentPage
                this.getPlans()
            },
            getPlans() {
                this.loading = true;
                let params = {
                    page: this.currentPage - 1,
                    entity: 'Tender',
                    length: 15,
                    filter: {tru: null}
                }

                if (this.selected) {
                    params.sort = [this.selected]
                }

                if ( this.filter ) {
                    // params.filter = {...this.filter}
                    for (const key in this.filter) {
                        if(this.filter[key]) {
                            params.filter[key] = this.filter[key]
                            // console.log(params.filter);
                        }
                    }
                }
                params.filter.isOOI = this.filter.isOOI ? 1 : 0;

                 this.$store.dispatch('GET_TENDER', params) 
                    .then(() => {
                        this.loading = false
                    })
            },
        },
        computed: {
            ...mapGetters([
                'TENDERS',
                'TENDER_LENGTH',
                'isAuth'
            ]),
            options() {
                return [
                    {
                        title: this.$t('sort.by_name'),
                        icon: 'fas fa-sort-alpha-down',
                        sortBy: {
                        field: "name_ru", 
                        by: "asc"
                        },
                    },
                    {
                        title: this.$t('sort.by_name'),
                        icon: 'fas fa-sort-alpha-up',
                        sortBy: {
                            field: 'name_ru',
                            by: 'desc',
                        },
                    },
                    {
                        title: this.$t('sort.by_day'),
                        icon: 'fas fa-sort-numeric-down',
                        sortBy: {
                            field: 'days',
                            by: 'asc',
                        },
                    },
                    {
                        title: this.$t('sort.by_day'),
                        icon: 'fas fa-sort-numeric-up',
                        sortBy: {
                            field: 'days',
                            by: 'desc',
                        },
                    },
                    {
                        title: this.$t('sort.by_area'),
                        icon: 'fas fa-sort-numeric-down',
                        sortBy: {
                            field: 'system',
                            by: 'asc',
                        },
                    },
                    {
                        title: this.$t('sort.by_area'),
                        icon: 'fas fa-sort-numeric-up',
                        sortBy: {
                            field: 'system',
                            by: 'desc',
                        },
                    },
                    {
                        title: this.$t('sort.by_status'),
                        icon: 'fas fa-sort-amount-down',
                        sortBy: {
                            field: 'status',
                            by: 'asc',
                        },
                    },
                    {
                        title: this.$t('sort.by_status'),
                        icon: 'fas fa-sort-amount-up',
                        sortBy: {
                            field: 'status',
                            by: 'desc',
                        },
                    },
                    {
                        title: this.$t('sort.by_sum'),
                        icon: 'fas fa-sort-numeric-down',
                        sortBy: {
                            field: 'sum',
                            by: 'asc',
                        },
                    },
                    {
                        title: this.$t('sort.by_sum'),
                        icon: 'fas fa-sort-numeric-up',
                        sortBy: {
                            field: 'sum',
                            by: 'desc',
                        },
                    },
                ];
            },
            pageCount() {
                let l = this.TENDER_LENGTH
                let s = this.size
                return Math.ceil(l/s)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .block__flex1 {
        .style-chooser {
            width: 100%;
        }
    }

    
    
</style>